import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import contexts from '../context/contexts';
import { NavMenu, Links } from './NavMenu';
import './MainLayout.less';

const {
	useStore: useLoginStore,
	useDispatch,
	makeDispatcher
} = contexts.login;

const HomeLayout = () => {
	const links = Links.map((a) => <a key={a.name} href={a.url}>{a.name}</a>);
	const dispatch = makeDispatcher(useDispatch());
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const { login } = useLoginStore();

	function onLogout(ev) {
		ev.preventDefault();
		dispatch({
			type: 'FETCH_LOGOUT',
			payload: {}
		});
	}

	useEffect(() => {
		if (!login.finishedLoading) {
			return setIsAuthenticated(false);
		}
		if (login.data.logged) {
			setIsAuthenticated(true);
		} else {
			setIsAuthenticated(false);
		}
	}, [login]);

	return (
		<div>
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Irish traditional set dancing classes - Brooks Academy
				</title>
			</Helmet>
			<div className="main-links">
				{links}
			</div>
			<NavMenu authenticated={isAuthenticated} onLogout={onLogout} />
			<div className="home-content">
				<Outlet />
			</div>
		</div>
	);
};

export default HomeLayout;
