import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { LinkContainer } from 'react-router-bootstrap';

export const Links = [
	{ name: 'Downloads', url: '/downloads' },
	// { name: 'Photos', url: '/photos' },
	// { name: 'Links', url: '/links' },
	{ name: 'Login', url: '/login' },
	{ name: 'About', url: '/about' },
	// { name: 'Calendar', url: '/calendar' },
];

export const NavMenu = ({ authenticated, expand = 'lg', onLogout }) => {
	return (
		<Navbar expand={expand} collapseOnSelect>
			<Navbar.Brand className="nav-link" href="/">
				<div className="banner">
					<h2>setdance.com</h2>
					<h3 className="text-2">Brooks Academy</h3>
				</div>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
				<Nav activeKey="/" className="justify-content-center">
					<Nav.Item>
						<LinkContainer to="/home">
							<Nav.Link >Home</Nav.Link>
						</LinkContainer>
					</Nav.Item>
					<Nav.Item>
						<LinkContainer to="/set-dances">
							<Nav.Link>Set Dances</Nav.Link>
						</LinkContainer>
					</Nav.Item>
					<Nav.Item>
						<LinkContainer to="/calendar">
							<Nav.Link>Calendar</Nav.Link>
						</LinkContainer>
					</Nav.Item>
					<NavDropdown title="Brooks Academy" id="basic-nav-dropdown" className="justify-content-end">
						<LinkContainer to="/about">
							<Nav.Link>About</Nav.Link>
						</LinkContainer>

						<LinkContainer to="/archive">
							<Nav.Link>Archive</Nav.Link>
						</LinkContainer>

						{!authenticated && (
						<LinkContainer to="/login">
							<Nav.Link>Login</Nav.Link>
						</LinkContainer>
						)}

						{authenticated && (
							<a className="nav-link" href="#" onClick={onLogout}>Logout</a>
						)}

					</NavDropdown>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};
