import React from 'react';
import { Helmet } from 'react-helmet';
import henrietta from '../../../images/15_henrietta_street.jpg';
import './About.less';

const About = () => {
	return (
		<div className="about">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Brooks Academy - About
				</title>
			</Helmet>
			<h1>About Brooks Academy</h1>
			<div className="box-content box">
				<img src={henrietta} className="gclef" />

				<p>
				Brooks Academy is the name of a group of set-dancers based at the premises of{' '}
				<a href="http://pipers.ie">Na Píobairí Uilleann</a> (The Pipers' Club)
				in Dublin's Henrietta
				Street. It was founded in 1982 by club members Terry and Kay Moylan and Jerry
				O'Reilly and Anne O'Reilly. From a beginning with just one set of dancers
				meeting once a week, it has grown to its present membership of around two
				hundred, with three classes per week.
				</p>
				<p>
				The club is run by a committee of six, who also teach the different classes, and
				which at present consists of Terry Moylan, Jerry O'Reilly, Irene Martin,
				Mary Friel, Eileen O'Doherty and Terry Cullen. The classes are structured on a
				graded basis, so that newcomers to set-dancing start with a class of beginners
				and can look forward to going through a three-year cycle with the same teacher,
				after which time they are considered to have been shown everything they can be
				shown and join our "club" night.
				</p>
				<p>
				In addition to our resident teachers,
				we regularly engage outside teachers to take over a class for a night.
				This is done in order to learn a new set or to expose our students at first
				hand to styles from different parts of the country. For the same reasons
				we organise trips to places where pre-revival set dancing is still strong
				so that our members can get to know the places, people, sets and styles
				from around Ireland.

				We have had Connie Ryan,
				Joe O'Donovan, Timmy

				"the Brit" McCarthy, Mick Mulkerrin and Muiris O'Brien to teach in Henrietta
				Street on many occasions, as well as many others on single occasions. Visits
				to dancers outside Dublin have been made to Mountshannon, Miltown Malbay,
				Lahinch, Feakle and Ennis in Clare, Ballyferriter, Dingle, Killarney and
				Kenmare in Kerry, as well as Knocknagree, Wexford, Dungarvan, Carna, Cúil
				Aodha, Westport and Cork City.
				</p>
				<p>
				Our trips and classes have a strong
				social aspect. The club has never been involved in the competition scene
				and our teaching is oriented strongly away from the "competition style",
				with its uniform dress and stylised manner of dancing. In our teaching
				we place great stress on the integration of set-dancing with music making,
				and we try to steer our members towards occasions where the dancing can
				grow out of the music, rather than the somewhat artificial céilí
				situation. We do also, however, run a monthly céilí
				in Dublin for the benefit of our members and friends, as not all our dancers
				are in the way of getting to the pub set-dance venues.
				We believe that we have made some
				valuable contributions to the present revival of set-dancing. We have been
				the first to dance and popularise several sets outside their "home" places.
				These include the Orange & Green Set, the Clare Lancers, the Sliabh
				Luachra Set (in Dublin), the Corca Dhuibhne Set, the Derradda Set, the
				Uibh Ráthach Set and the Waltz Cotillon. In addition we have, with
				other groups, helped popularise many more sets.
				</p>
				<p>
				Brooks Academy was the first group
				to publish a collection of detailed instructions for Irish set-dancing.
				Starting with Irish Dances, a collection of ten sets published in 1984,
				we have gone on to produce a total of five collections, comprising the
				instructions for fifty-seven sets. Irish Dances, The Piper's Set and other
				Dances and The Quadrilles and other Sets were edited by Terry Moylan, and
				Set Left - Set Right and The Walking Polka by Eileen O'Doherty.  You can find
				some of our published Irish set dances on this site.
				</p>
				<p>
				We were also the first ones to publish
				recordings of dance music specially arranged for set dancing. Produced
				by Irene Martin, our series of recordings was started in 1987 and now runs
				to six cassettes, each with the music for three different sets. This is
				an idea that has since been taken up and copied by many groups and musicians.
				In our recordings, unlike most others, we have made a point of using a
				variety of musical lineups, a feature which makes the tapes not just good
				dance music, but great music to listen to as well. An indication of their
				value in this regard is the fact that an independent music publisher has
				recently brought out a two volume collection of session tunes taken directly
				from our cassettes. We intend shortly to make and release further recordings
				in this series. Dancers from our club were involved in the production of
				the recent series of set dance videos The Magic of Irish Set Dancing, and
				feature on two videos of the series, dancing six different sets. Club members
				have also appeared on various TV shows including the Late Late Show, Kenny
				Live, The B.B. Show among others.
				</p>
				<p>
				As individuals, our teachers have
				been in demand as workshop instructors and as céilí callers
				throughout Dublin and Ireland. Jerry O'Reilly and Eileen O'Doherty in particular
				are very popular callers and are much in demand. As a club we have been
				invited every year since 1988 to Paris to conduct a set-dance workshop
				on behalf of the local Association Irlandaise, and members have also taught
				workshops in other parts of France, in England, America, Denmark and in
				Italy. In 1996 the club was twice invited to Paris to conduct traditional
				music events as part of the Imaginaire Irlandaise festival. Since 1985
				we have run one of the classes at the annual Willie Clancy Summer School,
				and in recent years have run a week-long series of céilís
				on behalf of the school.
				</p>
				<p>
				As a committee, we probably all
				feel that our group's greatest asset lies in the relationships that we
				have established with musicians and with "source" dancers around the country.
				We are confident that we enjoy the trust of the older dancers, who know
				that if they entrust us with their traditions they will be treated with
				respect and not abused or exploited. We try to teach a style of dancing
				that is relaxed and friendly, and to create occasions for dancing at which
				our informants themselves would feel comfortable. Our approach to set-dancing
				is based on the fact that it is a dance which is performed by a group of
				dancers together, and not by eight soloists.
				</p>
				<p>
				The present teachers have a considerable
				amount of experience covering all branches of Irish traditional music,
				song and dance.
				</p>
				<p>
				<b>Terry Moylan</b> is a piper. He was
				the first to produce collections of traditional sets, and was primarily
				instrumental, with his wife Kay, and Jerry and Anne O'Reilly, in founding
				Brooks Academy. He has edited several books on Irish music and dance and
				has also contributed articles to various publications.
				</p>
				<p>
				<b>Jerry O'Reilly</b> also plays the pipes
				but is better known, throughout Ireland, as a singer. He is one of the
				committee of the <a href="http://goilin.com" target="other">Góilín Singers Club</a>{' '}
				which meets every Friday night in Dublin, from September to May.
				</p>
				<p>
				<b>Irene Martin</b> has a long history
				in organising traditional music and song events in Dublin. A singer herself,
				for years she was involved in two of Dublin's most successful folk
				clubs, the Universal and the Coffee Kitchen, and was one of the organisers
				of the Dublin Traditional
				</p>
				<p>
				<b>Mary Friel</b> has also had considerable
				experience of organising traditional music events as a member of Comhaltas
				Ceoltóirí éireann. She is a step-dancer, plays the
				concertina and sings.
				</p>
				<p>
				<b>Eileen O'Doherty</b> is one of Ireland's
				leading collectors and publishers of set-dances. Her two collections include
				over forty dances, all of them notated from primary sources.
				</p>
				<p>
				<b>Terry Cullen</b> has been involved in
				traditional music since his youth, both as a dancer and singer.
				The teachers contribute their time
				to the club on a voluntary basis. All funds raised from membership
				or from activities are used either for publications or to
				support <a href="http://pipers.ie" target="other">Na Píobairí Uilleann</a>.
				</p>
				<p>
				Finally, why are we called "Brooks
				Academy"? Well, we needed a name and the song Lanigan's Ball had just been
				made popular by Christy Moore, so we lifted the name out of that. The hero
				of the song spent "six months at Brooks Academy, learning the steps for
				Lanigan's Ball".
				</p>
			</div>
		</div>
	);
};

export default About;
