import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import contexts from '../context/contexts';
import { NavMenu } from './NavMenu';
import './MainLayout.less';

const {
	useStore: useLoginStore,
	useDispatch,
	makeDispatcher
} = contexts.login;

const MainLayout = () => {
	const dispatch = makeDispatcher(useDispatch());
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const { login } = useLoginStore();

	function onLogout(ev) {
		ev.preventDefault();
		dispatch({
			type: 'FETCH_LOGOUT',
			payload: {}
		});
	}

	useEffect(() => {
		if (!login.finishedLoading) {
			return setIsAuthenticated(false);
		}
		if (login.data.logged) {
			setIsAuthenticated(true);
		} else {
			setIsAuthenticated(false);
		}
	}, [login]);

	return (
		<div>
			<NavMenu authenticated={isAuthenticated} onLogout={onLogout} />
			<div className="main-content">
				<Outlet />
			</div>
		</div>
	);
};

export default MainLayout;
